<template>
  <div class="cursor-default">
    <!-- Hачало секции формы -->
    <section :class="`block w-full h-full ${formPage == 1 ? 'firstImg' : formPage == 2 ? 'secondImg' : formPage == 3 ? 'thirdImg' : ''}`">
      <div class=" flex flex-col-reverse w-full">
        <img class="lg:hidden w-full max-h-[242px] h-full" :src="imgUrl" alt="">
        <div>
          <div class="lg:p-[64px] lg:max-w-7xl w-full lg:drop-shadow-md flex justify-end mx-[auto]">
              <div class="bg-white rounded-2xl lg:w-1/2 w-full">
              <div class="border-b border-solid border-[#EEEEEE]">
                <div class="flex justify-around items-center">
                  <div @click="formPage = 3" :class="`flex flex-col justify-center items-center px-[12px] pb-[20px] pt-[28px] max-w-[105px] w-full cursor-pointer ${formPage == 3 ? 'border-black border-b-[4px]': ''}`" >
                    <img src="@/assets/img/Frame2050.svg" class="w-[28px]" alt="">
                    <p :class="`text-[14px] text-center m-[8px] flex justify-center items-center hover:opacity-70 duration-100 ${formPage == 3 ? 'opacity-70': ''}`">Поездки</p>
                  </div>
                  <div @click="formPage = 2" :class="`flex flex-col justify-center items-center px-[12px] pb-[20px] pt-[28px] max-w-[105px] w-full cursor-pointer ${formPage == 2 ? 'border-black border-b-[4px]': ''}`">
                    <img src="@/assets/img/Frame2048.svg" class="w-[28px]" alt="">
                    <p :class="`text-[14px] text-center m-[8px] flex justify-center items-center hover:opacity-70 duration-100 ${formPage == 2 ? 'opacity-70': ''}`">Доставки</p>
                  </div>
                  <div @click="formPage = 1" :class="`flex flex-col justify-center items-center px-[12px] pb-[20px] pt-[28px] max-w-[105px] w-full cursor-pointer ${formPage == 1 ? 'border-black border-b-[4px]': ''}`">
                    <img class="w-[104px] h-[28px]" src="@/assets/img/driver.svg" alt="">
                    <p :class="`text-center text-[14px] m-[8px] flex justify-center items-center hover:opacity-70 duration-100 ${formPage == 1 ? 'opacity-70': ''}`">Водитель</p>
                  </div>
                </div>
              </div>
              <div class="lg:p-[48px] md:p-[32px] p-[24px]">
                <h1 class="lg:text-[52px] md:text-[44px] text-[36px] font-bold lg:leading-[54px] leading-[44px] select-none">
                  {{formMainText()}}
                </h1>
                <h2 class="my-[20px]">
                  {{formSecondText()}}
                </h2>
                <div v-if="formPage == 3" class="mt-[31px] relative mb-[13px] w-full">
                  <div>
                    <input @keyup ="searchFirstAddress()" v-model="firstAddress" placeholder="Введите свой адрес" class="w-full border-none rounded-2xl focus:ring-0 bg-[#f6f6f6] text-[16px] pr-[33px] py-[11px] pl-[41px] min-h-[52px]" type="text">
                    <span v-if="loader" class="loader absolute right-[13px] top-[18px]"></span>
                    <div v-else class="absolute right-[15px] top-[20px]">
                      <div @click="removeFirstAddress()" v-if="firstAddress">
                        <svg focusable="false" aria-hidden="true" width="12px" height="12px" fill="#c7bbbb" viewBox="0 0 12 12" role="button" aria-label="Delete pickup location" class="pe-location-clear css-ZSBbY"><path d="M10.595 0L6 4.596 1.405 0 0 1.404 4.596 6 0 10.595 1.405 12 6 7.404 10.595 12 12 10.595 7.404 6 12 1.404z"></path></svg>
                      </div>
                        <svg  v-else aria-hidden="true" focusable="false" width="16px" height="16px" fill="#c7bbbb" viewBox="0 0 24 24" role="button" aria-label="Locate me" class="pe-location-fetch css-ZSBbY"><path d="M10.5 13.5L.5 11 21 3l-8 20.5-2.5-10z"></path></svg>
                    </div>
                    <ul v-if="firstAddress" :class="`absolute address-list mt-[6px] bg-[#f6f6f6] rounded-2xl z-10 w-full ${addresses.length > 5 ? 'h-[220px] overflow-y-scroll': ''} text-ellipsis`">
                      <div class="hover:bg-[#dddcdc]" v-for="address in addresses">
                        <li v-if="address.type === 'fast_address'" @click="getFirstAddress(address)" :key="address.id" class="text-ellipsis flex items-center justify-between pl-[45px] pr-[30px] py-[9px] overflow-x-hidden duration-150 cursor-pointer">
                          <div>
                            <div class="text-[#343434] font-[500] text-[14px]">
                              {{ address.name }}
                            </div>
                            <div v-if="address.street_type == 'микрорайон'" class="text-[#A7A7A7] text-[12px]">
                              {{ (address.street ? address.street : '') + ' ' + (address.street_type ? address.street_type: ' ') + ' ' +  (address.addr_relation ? ',' + address.addr_relation : '') }}
                            </div>
                            <div v-else class="text-[#A7A7A7] text-[12px]">
                              {{ (address.street_type ? address.street_type: ' ') + ' ' + (address.street ? address.street : '') + ' ' + (address.addr_relation ? ',' + address.addr_relation : '') }}
                            </div>
                          </div>
                          <img class="" src="@/assets/img/address-list.svg" alt="">
                        </li>
                        <li v-if="address.type === 'address'" @click="getFirstAddress(address)" :key="address.id" class="text-ellipsis flex items-center justify-between pl-[45px] pr-[30px] py-[9px] overflow-x-hidden duration-150 cursor-pointer">
                          <div v-if="address.street_type == 'микрорайон'">
                            <div class="text-[#343434] font-[500] text-[14px]">
                              {{ address.street + ' ' + address.street_type + ', ' + address.name }}
                            </div>
                            <div class="text-[#A7A7A7] text-[12px]">
                              {{ address.village + ', ' + address.region }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="text-[#343434] font-[500] text-[14px]">
                              {{ address.street_type + ' ' + address.street + ', ' + address.name }}
                            </div>
                            <div class="text-[#A7A7A7] text-[12px]">
                              {{ address.village + ', ' + address.region }}
                            </div>
                          </div>
                          <img class="" src="@/assets/img/address-list.svg" alt="">
                        </li>
                        <hr v-if="addresses.length > 1" class="bg-[#D1D1CF] h-[2px] mx-[30px]">
                      </div>
                      <li v-if="addressesArrLength"  class="text-ellipsis pl-[45px] pr-[30px] py-[10px] overflow-x-hidden hover:bg-[#dddcdc] duration-150 cursor-pointer">Адрес не найден</li>
                    </ul>
                  </div>
                  <div class="mt-[8px]">
                    <input @input="searchSecondAddress()" v-model="secondAddress" placeholder="Введите пункт назначения" class="w-full border-none rounded-2xl focus:ring-0 bg-[#f6f6f6] text-[16px] pr-[33px] py-[11px] pl-[41px] min-h-[52px]" type="text">
                    <span v-if="loader2" class="loader absolute right-[13px] bottom-[16px]"></span>
                    <div v-else class="absolute right-[15px] bottom-[20px]">
                      <div @click="removeSecondAddress()" v-if="secondAddress">
                          <svg aria-hidden="true" focusable="false" width="12px" height="12px" fill="#c7bbbb" viewBox="0 0 12 12" role="button" aria-label="Delete pickup location" class="pe-location-clear css-ZSBbY"><path d="M10.595 0L6 4.596 1.405 0 0 1.404 4.596 6 0 10.595 1.405 12 6 7.404 10.595 12 12 10.595 7.404 6 12 1.404z"></path></svg>
                      </div>
                    </div>
                    <ul v-if="secondAddress" :class="`absolute address-list mt-[6px] bg-[#f6f6f6] rounded-2xl z-10 w-full ${addresses2.length > 5 ? 'h-[220px] overflow-y-scroll': ''} text-ellipsis`">
                      <div class="hover:bg-[#dddcdc]" v-for="address in addresses2">
                        <li v-if="address.type === 'fast_address'" @click="getSecondAddress(address)" :key="address.id" class="text-ellipsis flex items-center justify-between pl-[45px] pr-[30px] py-[9px] overflow-x-hidden duration-150 cursor-pointer">
                          <div>
                            <div class="text-[#343434] font-[500] text-[14px]">
                              {{ address.name }}
                            </div>
                            <div v-if="address.street_type == 'микрорайон'" class="text-[#A7A7A7] text-[12px]">
                              {{ (address.street ? address.street : '') + ' ' + (address.street_type ? address.street_type: ' ') + ' ' +  (address.addr_relation ? ',' + address.addr_relation : '') }}
                            </div>
                            <div v-else class="text-[#A7A7A7] text-[12px]">
                              {{ (address.street_type ? address.street_type: ' ') + ' ' + (address.street ? address.street : '') + ' ' + (address.addr_relation ? ',' + address.addr_relation : '') }}
                            </div>
                          </div>
                          <img class="" src="@/assets/img/address-list.svg" alt="">
                        </li>
                        <li v-if="address.type === 'address'" @click="getSecondAddress(address)" :key="address.id" class="text-ellipsis flex items-center justify-between pl-[45px] pr-[30px] py-[9px] overflow-x-hidden duration-150 cursor-pointer">
                          <div v-if="address.street_type == 'микрорайон'">
                            <div class="text-[#343434] font-[500] text-[14px]">
                              {{ address.street + ' ' + address.street_type + ', ' + address.name }}
                            </div>
                            <div class="text-[#A7A7A7] text-[12px]">
                              {{ address.village + ', ' + address.region }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="text-[#343434] font-[500] text-[14px]">
                              {{ address.street_type + ' ' + address.street + ', ' + address.name }}
                            </div>
                            <div class="text-[#A7A7A7] text-[12px]">
                              {{ address.village + ', ' + address.region }}
                            </div>
                          </div>
                          <img class="" src="@/assets/img/address-list.svg" alt="">
                        </li>
                        <hr v-if="addresses2.length > 1" class="bg-[#D1D1CF] h-[2px] mx-[30px]">
                      </div>
                      <li v-if="addressesArrLength"  class="text-ellipsis pl-[45px] pr-[30px] py-[10px] overflow-x-hidden hover:bg-[#dddcdc] duration-150 cursor-pointer">Адрес не найден</li>
                    </ul>
                  </div>
                  <div :class="`line z-0 ${(addresses.length > 0 && firstAddress) || addressesArrLength ? '' : 'bg-black'}`"></div>
                </div>
                <div v-if="formPage == 3" class="relative">
                  <input id="tariff" @focus="openTariffList = true" @focusout="unfocusInput()" v-model="tariff" placeholder="Выберите тариф" class="w-full rounded-2xl focus:ring-0 border-none bg-[#f6f6f6] text-[16px] pr-[33px] py-[11px] pl-[41px] min-h-[52px]" type="text">
                  <div class="absolute right-[15px] top-[20px]" v-if="tariff">
                    <svg @click="clearTariff()" focusable="false" aria-hidden="true" width="12px" height="12px" fill="#c7bbbb" viewBox="0 0 12 12" role="button" aria-label="Delete pickup location" class="pe-location-clear css-ZSBbY"><path d="M10.595 0L6 4.596 1.405 0 0 1.404 4.596 6 0 10.595 1.405 12 6 7.404 10.595 12 12 10.595 7.404 6 12 1.404z"></path></svg>
                  </div>
                  <ul v-if="openTariffList" :class="`absolute bg-[#f6f6f6] z-10 w-full ${tariffList.length > 5 ? 'h-[220px] overflow-y-scroll': ''} text-ellipsis`">
                    <li v-for="tariff in tariffList" @click="getTariff(tariff)" :key="tariff.id" class="text-ellipsis pl-[45px] pr-[30px] py-[10px] overflow-x-hidden hover:bg-[#dddcdc] duration-150 cursor-pointer">{{ tariff.name }}</li>
                  </ul>
                </div>
                <div class=" flex flex-wrap flex-col content-start">
                  <div :class="`${formPage == 3 ? 'mt-[19px] flex items-baseline' : ''}`">
                    <a href="#" v-if="formPage != 1">
                      <button class="no-underline border-none rounded-[10px] leading-[20px] font-medium hover:opacity-80 mt-[12px] cursor-pointer duration-200 px-[25px] py-[14px] bg-black text-white">
                        Скоро
                      </button>
                    </a>
                    <router-link to="/KZ/astana/driver"  v-else>
                      <button class="no-underline border-none rounded-[10px] leading-[20px] font-medium hover:opacity-80 mt-[12px] cursor-pointer duration-200 px-[25px] py-[14px] bg-black text-white">
                        {{buttonText()}} 
                      </button>
                    </router-link>
                    <div v-if="formPage == 3" class="flex justify-start ml-[20px] lg:ml-[50px]">
                      <h3 class="lg:text-[22px] font-semibold md:text-[22px] text-[18px]">Цена: <span class="text-green-500">{{price + 'смн.'}}</span></h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Конец секции формы -->

    <!-- Начало секции Gram Eats -->
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 mb-[36px] lg:mb-0 flex justify-center flex-col">
              <h2 class="lg:text-[36px] md:text-[32px] text-[28px] font-bold mb-[24px]">
                {{deliGramText}}
              </h2>
              <p class="mb-[15px]">
                {{deliGramInfo}}
              </p>
              <div>
              <a :href="deliGramLink" v-if="formPage != 1">
                <button class="border-none text-[16px] leading-[20px] font-medium rounded-[10px] hover:opacity-80 mt-[12px] duration-200 px-[25px] py-[14px] bg-black text-white">
                  Заказать
                </button>
              </a>
              <router-link to="/KZ/astana/driver" v-else>
                <button class="border-none text-[16px] leading-[20px] font-medium rounded-[10px] hover:opacity-80 mt-[12px] duration-200 px-[25px] py-[14px] bg-black text-white">
                  Зарегистрироваться
                </button>
              </router-link>
              </div>
            </div>
            <div class="lg:col-span-6">
              <img class="w-full h-full" :src="imgSecondUrl" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Конец секции Gram Eats -->

    <!-- Начло секции о нас -->
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] md:grid md:grid-cols-12 md:gap-[36px]">
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img v-if="formPage == 1" class="mb-[20px] w-[50px]" src="@/assets/img/icon/carbon_flash.svg" alt="">
              <img v-else class="mb-[20px] w-[50px]" src="@/assets/img/icon/speed.svg" alt="">
              <div>
                <h4 v-if="formPage != 1" class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  Быстро и просто
                </h4>
                <h4 v-if="formPage == 1" class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  Быстрый старт
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p v-if="formPage == 3">
                  Подача за считанные минуты
                </p>
                <p v-if="formPage == 2">
                  Доставка от 15 минут
                </p>
                <p v-if="formPage == 1">
                  Всего несколько часов от анкеты до первого заказа
                </p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img v-if="formPage == 1" class="mb-[20px] w-[50px]" src="@/assets/img/icon/mdi_clock-outline.svg" alt="">
              <img v-else class="mb-[20px] w-[50px]" src="@/assets/img/icon/majesticons_coins-line.svg" alt="">
              <div>
                <h4 v-if="formPage != 1" class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  Отличные цены
                </h4>
                <h4 v-if="formPage == 1" class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  Гибкий график
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p v-if="formPage == 3">
                  Мы стремимся предлагать лучшие цены на поездки в вашем городе
                </p>
                <p v-if="formPage == 2">
                  Закажите доставку из любой точки по оптимальной цене
                </p>
                <p v-if="formPage == 1">
                  Работайте несколько часов в день или полную смену
                </p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img v-if="formPage == 1" class="mb-[20px] w-[50px]" src="@/assets/img/icon/person.svg" alt="">
              <img v-else class="mb-[20px] w-[50px]" src="@/assets/img/icon/mdi_clock-outline.svg" alt="">
              <div>
                <h4 v-if="formPage != 1" class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  Удобно
                </h4>
                <h4 v-if="formPage == 1" class="text-[20px] font-medium leading-[24px] mb-[12px]">
                 Работайте на себя
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p v-if="formPage == 3">
                  Доберитесь куда угодно - всего в пару касаний
                </p>
                <p v-if="formPage == 2">
                  Закажите еду, продукты или лекарства в любое время 
                </p>
                <p v-if="formPage == 1">
                  Станьте самозанятым чтобы не платить лишние комиссии
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Конец секции о нас -->

    <!-- Начало секции приложения -->
    <!-- <section class="bg-[#f6f6f6]">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <h2 class="lg:text-[36px] md:text-[32px] md:leading-[40px] leading-[36px] text-[28px] lg:leading-[44px] font-bold mb-[12px]">Оптимизируйте свою работу</h2>
          <h4 class="lg:text-[20px] md:text-[20px] md:leading-[18px] leading-[20px] text-[18px] lg:leading-[20px] mb-[36px]">Cкачайте приложение для быстрого доступа к заказам!</h4>
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] flex flex-col md:gap-[36px]">
            <div @mouseover="apphover1 = true" @mouseout="apphover1 = false" class="w-full relative cursor-pointer lg:col-span-6 mb-[36px] bg-white lg:mb-0">
              <a href="https://play.google.com/store/apps/details?id=com.iogram.traffic" class="flex items-center w-full">
                <div class="lg:my-[24px] lg:ml-[24px] my-[16px] ml-[16px]">
                  <img src="@/assets/img/trafficGram.png" class="md:w-[200px] lg:w-[192px] w-[192px]" alt="">
                </div>
                <div class="lg:py-[24px] py-[16px] pl-[16px] lg:pl-[24px] pr-[48px]">
                  <h3 class="lg:text-[20px] text-[20px] font-bold leading-[32px]">
                    Скачать приложение для водителей
                  </h3>
                </div>
                <div :class="`absolute duration-300 ${apphover1 ? 'right-[10px]': 'right-[24px]' }`">
                  <img src="@/assets/img/arrow.svg" class="w-[25px]" alt="">
                </div>
              </a>
            </div>
            <div @mouseover="apphover2 = true" @mouseout="apphover2 = false" class="w-full relative cursor-pointer lg:col-span-6 mb-[36px] bg-white lg:mb-0">
              <a href="https://play.google.com/store/apps/details?id=com.gram.client" class="flex items-center w-full h-full">
                <div class="lg:my-[24px] lg:ml-[24px] my-[16px] ml-[16px]">
                  <img src="@/assets/img/logo.svg" class="" alt="">
                </div>
                <div class="lg:py-[24px] py-[16px] pl-[16px] lg:pl-[24px] pr-[48px]">
                  <h3 class="lg:text-[20px] text-[20px] font-bold leading-[32px]">
                    Скачать приложение для пассажиров 
                  </h3>
                </div>
                <div :class="`absolute duration-300 ${apphover2 ? 'right-[10px]': 'right-[24px]' }`">
                  <img src="@/assets/img/arrow.svg" class="w-[25px]" alt="">
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- Конец секции приложения -->
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] md:grid md:grid-cols-12 md:gap-[36px]">
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img class="mb-[20px] w-[50px]" src="@/assets/img/group_icon.svg" alt="">
              <div>
                <h4  class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  О нас
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p >
                  Узнайте, как мы начинали, что нами движет и куда мы стремимся.
                </p>
              </div>
              <div class="pt-[12px] pb-[26px]" >
                <div class="absolute font-bold">
                  <router-link class="cursor-pointer" to="/KZ/astana/about" @mouseover="hover1 = true" @mouseout="hover1 = false">
                    Узнайте больше о Gram
                    <div class="w-full h-[1px] bg-[#cbcbcb] relative top-[1px]"></div>
                    <div :class="`${hover1 ? 'w-full' : 'w-0'} h-[1px] rounded-sm duration-300 bg-black relative`"></div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img class="mb-[20px] w-[50px]" src="@/assets/img/icons8-news.svg" alt="">
              <div>
                <h4 class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  Блог
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p >
                  Следите за новостями о наших новых проектах и достижениях.
                </p>
              </div>
              <div class="pt-[12px] pb-[26px]" >
                <div class="absolute font-bold">
                  <router-link class="cursor-pointer" to="/KZ/astana/news" @mouseover="hover = true" @mouseout="hover = false">
                    Перейти в отдел новостей
                    <div class="w-full h-[1px] bg-[#cbcbcb] relative top-[1px]"></div>
                    <div :class="`${hover ? 'w-full' : 'w-0'} h-[1px] rounded-sm duration-300 bg-black relative`"></div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img class="mb-[20px] w-[40px]" src="@/assets/img/career.svg" alt="">
              <div>
                <h4  class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  Карьера
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p >
                  Присоединяйтесь к нашей команде и развивайтесь вместе с нами.
                </p>
              </div>
              <div class="pt-[12px] pb-[26px]" >
                <div class="absolute font-bold">
                  <a class="cursor-pointer" href="http://job.gram.tj/index.html" @mouseover="hover2 = true" @mouseout="hover2 = false">
                    Узнать о доступных вакансиях
                    <div class="w-full h-[1px] bg-[#cbcbcb] relative top-[1px]"></div>
                    <div :class="`${hover2 ? 'w-full' : 'w-0'} h-[1px] rounded-sm duration-300 bg-black relative`"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        <!-- Секция Gram бизнес -->
    <section class="bussines">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px] text-white">
          <div class="mb-[40px]">
            <h2 class="lg:text-[36px] lg:leading-[44px] md:leading-[40px] leading-[36px] md:text-[32px] text-[28px] font-bold">
              Gram для бизнеса
            </h2>
          </div>
          <div class="mb-[20px] leading-[24px]">
            <p class="text-[16px] font-normal">
              Продвигайте свой бизнес вперед вместе с Gram
            </p>
          </div>
          <div class="mt-[82px]">
            <!-- <router-link to="/KZ/astana/business"> -->
              <button disabled class="border-none leading-[20px] font-medium rounded-[10px] text-[16px] hover:opacity-80 mt-[12px] duration-200 px-[25px] py-[14px] bg-white text-black">
                Скоро
              </button>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
    </section>
    <!-- Конец секции Gram бизнес -->

    <!-- Начало секции регистрация -->
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] flex flex-col gap-[36px]">
            <div @mouseover="registerHover1 = true" @mouseout="registerHover1 = false" :class="` ${registerHover1 ? 'opacity-80' : ''} duration-[400ms] lg:col-span-6 cursor-pointer pb-[23px] md:pb-[30px] lg:pb-[20px] border-black border-b-[1px]`">
              <router-link to="/KZ/astana/driver">
                <div class="grid grid-cols-12">
                  <h2 class="lg:text-[36px] md:text-[32px] col-span-11 md:leading-[40px] text-[28px] leading-[36px] lg:leading-[44px] font-bold">
                    Начните зарабатывать с Gram
                  </h2>
                  <div class="relative">
                    <div class=" flex justify-end absolute overflow-hidden">
                      <div class="flex items-center justify-center float-right  ">
                        <div :class="`relative ${registerHover1 ? 'right-0 duration-500' : 'right-[70px]'}`">
                          <img src="@/assets/img/arrow.svg" class="w-[48px]" alt="">
                        </div>
                        <div :class="`absolute ${registerHover1 ? 'right-[-70px] duration-500' : 'right-0'}`">
                          <img src="@/assets/img/arrow.svg" class="w-[48px]" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <!-- <div @mouseover="registerHover2 = true" @mouseout="registerHover2 = false" :class="` ${registerHover2 ? 'opacity-80' : ''} duration-[400ms] lg:col-span-6 cursor-pointer pb-[23px] md:pb-[30px] lg:pb-[20px] border-black border-b-[1px]`">
              <a href="https://play.google.com/store/apps/details?id=com.gram.client">
                <div class="grid grid-cols-12">
                    <h2 class="lg:text-[36px] md:text-[32px] col-span-11 md:leading-[40px] text-[28px] leading-[36px] lg:leading-[44px] font-bold">
                      Закажи такси
                    </h2>
                  <div class="relative">
                    <div class=" flex justify-end absolute overflow-hidden">
                      <div class="flex items-center justify-center float-right  ">
                        <div :class="`relative ${registerHover2 ? 'right-0 duration-500' : 'right-[70px]'}`">
                          <img src="@/assets/img/arrow.svg" class="w-[48px]" alt="">
                        </div>
                        <div :class="`absolute ${registerHover2 ? 'right-[-70px] duration-500' : 'right-0'}`">
                          <img src="@/assets/img/arrow.svg" class="w-[48px] sm:w-[]" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div> -->
            <div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Конец секции регистрация -->
    <div v-if="showButton" @click="scrollToTop" class="fixed bottom-[20px] right-[20px]">
      <button style="box-shadow: 0px 1px 31px 4px rgba(34, 60, 80, 0.2)" class="border-none p-[15px] bg-white rounded-full">
        <div class="flex">
          <img class="-rotate-90 w-[25px]" src="@/assets/img/arrow.svg" alt="">
        </div>
      </button>
    </div>
  <!-- <Footer></Footer> -->
</div>
</template>

<script>
import Footer from "@/components/layouts/Footer.vue"
export default {
  components: {
    Footer
  },
  data(){
    return {
      showButton: false,
      loader: false,
      addresses: [],
      addresses2: [],
      hover: false,
      buttonHref: 'https://play.google.com/store/apps/details?id=com.gram.client',
      price: 0,
      loader2: false,
      apphover1: false,
      registerHover2: false,
      registerHover1: false,
      apphover2: false,
      formPage: '3',
      firstAddressId: undefined,
      secondAddressId: undefined,
      firstAddress: null,
      secondAddress: undefined,
      timeout:null,
      addressesArrLength: false,
      addresses2ArrLength: false,
      hover1: false,
      hover2: false,
      imgName: 'ride',
      deliGramText: 'Быстрые и доступные поездки',
      deliGramLink: 'https://play.google.com/store/apps/details?id=com.gram.client',
      deliGramInfo: 'Закажите такси Gram через сайт или приложение и наслаждайтесь поездкой',
      openTariffList: false,
      tariff: 'Стандарт',
      tariff_id: 1,
      tariffList: []
    }
  },
  mounted() {
    window.scrollTo({ top: 0 })
    window.addEventListener('scroll', this.handleScroll);
    this.getTariffs()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    imgUrl(){
      return require('@/assets/img/' + this.formPage + '.jpg')
    },
    imgSecondUrl(){
      return require('@/assets/img/' + this.imgName + '.jpg')
    }
  },
  watch: {
    secondAddress(){
      if (!this.secondAddress) {
        this.price = 0
      }
    },
    firstAddress(){
      if (this.firstAddress) {
        this.addresses = this.addresses
      } else {
        this.addresses.length = 0
        this.addressesArrLength = false
      }
    },
    formPage(){
      if (this.formPage == 2) {
        this.imgName = 'deliGram'
        this.deliGramText = 'Экономьте время с deliGram'
        this.deliGramInfo = 'Доставка поможет быстро передать забытую вещь, отвезти/забрать документы или привезти вкусную еду.'
      } else if (this.formPage == 3) {
        this.imgName = 'ride'
        this.deliGramText = 'Быстрые и доступные поездки'
        this.deliGramInfo = 'Закажите такси Gram через сайт или приложение и наслаждайтесь поездкой'
      } else {
        this.imgName = 'driver_page'
        this.deliGramText = 'Найдите работу по душе'
        this.deliGramInfo = 'Станьте водителем и зарабатывайте от 100 смн в день'
      }
    }
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 700;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    searchFirstAddress(){
      if (this.firstAddress) {
        clearTimeout(this.timeout)
        this.timeout=setTimeout(()=>{
        this.loader = true
          this.$axios.post('/orders/search-addresses', {search: this.firstAddress})
          .then(res => {
            if (res.data.result.length == 0) {
              this.addressesArrLength = true
            } else {
              this.addressesArrLength = false
            }
            this.addresses = res.data.result
            this.loader = false
          })
          .catch(err => {
            this.loader = false
          })
        }, 500)
      }
    },
    searchSecondAddress(){
      if (this.secondAddress) {
        clearTimeout(this.timeout)
        this.timeout=setTimeout(()=>{
        this.loader2 = true
          this.$axios.post('/orders/search-addresses', {search: this.secondAddress})
          .then(res => {
            if (res.data.result.length == 0) {
              this.addresses2ArrLength = true
            } else {
              this.addresses2ArrLength = false
            }
            this.addresses2 = res.data.result
            this.loader2 = false
          })
          .catch(err => {
            this.loader2 = false
          })
        }, 500)
      }
    },
    getFirstAddress(address){
      if (address.street_type == 'микрорайон') {
        this.firstAddress = address.street + ' ' + address.street_type + ', ' + address.name
      }else {
        this.firstAddress = address.name
      }
      this.addresses = []
      this.firstAddressId = address.id
      this.calculate()
    },
    getSecondAddress(address){
      if (address.street_type == 'микрорайон') {
        this.secondAddress = address.street + ' ' + address.street_type + ', ' + address.name
      }else {
        this.secondAddress = address.name
      }
      this.addresses2 = []
      this.secondAddressId = address.id
      this.calculate()
    },
    unfocusInput() {
      setTimeout(() => {
        this.openTariffList = false
      }, 100);
    },
    getTariffs() {
      this.$axios.get('orders/tariffs')
      .then(res => {
        this.tariffList = res.data.result
        let firstTariff = this.tariffList[0]
        this.tariff = firstTariff.name
        this.tariff_id = firstTariff.id
        this.price = 'от ' + firstTariff.min_price
      })
      .catch(err => {
      })
    },
    getTariff(val) {
      this.tariff = val.name
      this.tariff_id = val.id
      this.price = 'от ' + val.min_price
      this.calculate()
    },
    clearTariff() {
      this.tariff = null
      this.tariff_id = null
    },
    calculate() {
      let order = {
        tariff_ids: [{tariff_id: this.tariff_id}],
        allowances: undefined,
        search_address_id: this.firstAddressId,
        to_addresses: []
      }
      order.tariff_ids = JSON.stringify(order.tariff_ids)
      if (this.secondAddressId) {
        order.to_addresses.push({search_address_id: this.secondAddressId})
      }
      order.to_addresses = JSON.stringify(order.to_addresses)
      this.$axios.post('/orders/calculate', order)
      .then(res => {
        if (this.secondAddressId) {
          this.price = res.data.result[0].amount
        }else {
          this.price = 'от ' + res.data.result[0].amount
        }
      })
      .catch(err => {
      })
    },
    formSecondText(){
      if (this.formPage == 1) {
        return 'Станьте частью нашей команды Gram'
      } else if (this.formPage == 2) {
        return 'Закажите все что вам нужно в один клик'
      } else {
        return ''
      }
    },
    formMainText() {
      if (this.formPage == 1) {
        return 'Стань водителем и начни зарабатывать'
      } else if (this.formPage == 2) {
        return 'C deliGram доставка быстрее'
      } else {
        return 'Заказать поездку сейчас'
      }
    },
    formInformation(){
       if (this.formPage == 2) {
        return 'Владеете рестораном? Стать партнером'
      } else {
        return ''
      }
    },
    buttonText() {
      if (this.formPage == 1) {
        return 'Зарегистрироваться'
      } else if (this.formPage == 2) {
        return 'Заказать'
      } else {
        return 'Заказать'
      }
    },
    removeFirstAddress(){
      this.firstAddress = ''
      this.firstAddressId = undefined
      this.addresses = []
    },
    removeSecondAddress(){
      this.secondAddress = ''
      this.addresses2 = []
      this.secondAddressId = undefined
      this.calculate()
    },
  }
}
</script>

<style scoped>
.bussines {
  background-image: url(@/assets/бизнес.jpg);
  background-position: left left;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 747px) {
  .bussines {
    background-image: url(@/assets/img/bussenes.jpg);
  }
}

@media screen and (min-width: 1441px) {
  .firstImg {
    background-image: url(@/assets/img/driver3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    height: 701px;
  }
  .secondImg {
    background-image: url(@/assets/img/8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 701px;
  }
  .thirdImg {
    background-image: url(@/assets/1.1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    height: 701px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .firstImg {
    background-image: url(@/assets/img/driver3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 25% 75%;
    height: 701px;
  }
  .secondImg {
    background-image: url(@/assets/img/8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 15% 85%;
    height: 701px;
  }
  .thirdImg {
    background-image: url(@/assets/1.1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20% 80%;
    height: 701px;
  }
}

.line {
  position: absolute;
  top: 33px;
  bottom: 33px;
  left: 22px;
  width: 1px;
}
.line::before {
  content: "";
  position: absolute;
  left: -3px;
  width: 7px;
  height: 7px;
  bottom: -10px;
  border: 1px solid black;
  background-color: #f6f6f6;
}
.line::after {
  content: "";
  position: absolute;
  border: 1px solid black;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  left: -3px;
  top: -10px;
}
svg:hover {
  fill: #3f3e3e;
}
.loader {
    width: 20px;
    height: 20px;
    border: 3px solid #c7bbbb;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 
</style>